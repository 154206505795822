/* JASV CSS */
html, body {
  font-size: 18px;
}
.login-box {
  width: 420px;
}

/* JASV Color */
body[data-theme=jasv] footer.footer {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, .5), #fff 3%, #fff);
}

body[data-theme=jasv] .navbar-bg {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, .5), #fff 3%, #fff);
}

body[data-theme=jasv] .sidebar {
  background: #E3E0DB;
}

body[data-theme=jasv] .sidebar-content {
  background: #E3E0DB;
  background: linear-gradient(135deg,#E3E0DB 10%,#E3E0DB 40%,#D0CCC4);
}

body[data-theme=jasv] .sidebar-link, body[data-theme=jasv] a.sidebar-link {
  background: transparent;
  border-left-color: transparent;
  color: #495057;
}

body[data-theme=jasv] .sidebar-link i, body[data-theme=jasv] .sidebar-link svg, body[data-theme=jasv] a.sidebar-link i, body[data-theme=jasv] a.sidebar-link svg {
  color: #495057;
}

body[data-theme=jasv] .sidebar-link:hover {
  background: transparent;
  border-left-color: transparent;
  color: #212529;
}

body[data-theme=jasv] .sidebar-link:hover i, body[data-theme=jasv] .sidebar-link:hover svg {
  color: #212529;
}

body[data-theme=jasv] .sidebar-item.active .sidebar-link:hover, body[data-theme=jasv] .sidebar-item.active>.sidebar-link {
  color: #212529;
  color: #000;
  background: linear-gradient(90deg,rgba(252,114,3,.2),rgba(252,114,3,.188) 50%,transparent);
  border-left-color: #FC7203;
}

body[data-theme=jasv] .sidebar-item.active .sidebar-link:hover i, body[data-theme=jasv] .sidebar-item.active .sidebar-link:hover svg, body[data-theme=jasv] .sidebar-item.active>.sidebar-link i, body[data-theme=jasv] .sidebar-item.active>.sidebar-link svg {
  color: #212529;
  color: #000;
}

body[data-theme=jasv] .sidebar-dropdown .sidebar-link {
  background: transparent;
  color: #6c757d;
}

body[data-theme=jasv] .sidebar-dropdown .sidebar-item .sidebar-link:hover {
  background: transparent;
  color: #212529;
}

body[data-theme=jasv] .sidebar-dropdown .sidebar-item.active .sidebar-link {
  background: transparent;
  color: #212529;
}

body[data-theme=jasv] .sidebar-brand {
  color: #000;
  padding: 0.68rem 1.5rem 0.6rem;
}

body[data-theme=jasv] .sidebar-brand:hover {
  color: #495057;
}

body[data-theme=jasv] .sidebar-header {
  color: #000;
}

body[data-theme=jasv] .sidebar-cta-content {
  background: #f2f4f6;
  color: #343a40;
}

body[data-theme=jasv] .sidebar-user-title, body[data-theme=jasv] .sidebar-user-title:hover {
  color: #020202;
}

body[data-theme=jasv] .sidebar-user-subtitle {
  color: #6c757d;
}

body[data-theme=jasv] .wrapper {
  background: #E3E0DB;
}

body[data-theme=jasv] .simplebar-scrollbar:before {
  background: #adb5bd;
}

/* btn-tertiary */
.btn-tertiary {
  background-color: #df7620;
  border-color: #df7620;
  color: #fff
}

.btn-check:focus+.btn-tertiary, .btn-tertiary:focus, .btn-tertiary:hover {
  background-color: #ca6a1c;
  border-color: #ca6a1c#ca6a1c;
  color: #fff
}

.btn-check:focus+.btn-tertiary, .btn-tertiary:focus {
  box-shadow: 0 0 0 .2rem #df762080;
}

.btn-check:active+.btn-tertiary, .btn-check:checked+.btn-tertiary, .btn-tertiary.active, .btn-tertiary:active, .show>.btn-tertiary.dropdown-toggle {
  background-color: #ca6a1c;
  border-color: #ca6a1c;
  color: #fff
}

.btn-check:active+.btn-tertiary:focus, .btn-check:checked+.btn-tertiary:focus, .btn-tertiary.active:focus, .btn-tertiary:active:focus, .show>.btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #df762080;
}

.btn-tertiary.disabled, .btn-tertiary:disabled {
  background-color:  #df7620;
  border-color:  #df7620;
  color: #fff
}
.btn-tertiary, .btn-tertiary.disabled, .btn-tertiary.focus, .btn-tertiary.hover:not(:disabled):not(.disabled), .btn-tertiary:disabled, .btn-tertiary:focus, .btn-tertiary:hover:not(:disabled):not(.disabled) {
  color: #fff;
}

.btn-group-menu-lg>.btn, .btn-menu-lg {
  border-radius: 0.3rem;
  font-size: .925rem;
  padding: 1.25rem 1rem;
}
