
/* JASV analysis */


/* ����@���y�[�W */
/*
.page-break-before  {
	page-break-before:always
}
*/
@media print {
	.page-break-before  {
		page-break-before:always
	}
}

.mt-print {
	margin-top: 50px;
}

/* ���ڂ̑I�����̃J���[ */
.table-select tr.active td {
	color: #055160;
	background-color: #cff4fc;
	border-color: #b6effb;
}

/* �����N�J���[ */
.rankA, .rankB, .rankC, .rankD, .rankE, .rankF {
	font-weight: 700;
	text-align: center;
}
.rankA {color:#FF0000 !important;}
.rankB {color:#FFBF00 !important;}
.rankC {color:#2FB353 !important;}
.rankD {color:#00B7EF !important;}
.rankE {color:#AC10D6 !important;}
.rankF {color:#555555 !important;}

/* ��̓f�[�^�F���Y���c���[�֘A */
.tree-rank,
.tree-sub-text {
	font-size: 0.60rem;
	font-weight: 400;
}
.tree-text-sm {
	font-size: 0.75rem!important;
}
.tree-text-sm>* {
	line-height: 1.3!important;
}

/* tbody���X�N���[���i��͍��ځj */
.table-scroll thead,
.table-scroll tbody {
	display: block;
}
.table-scroll tbody {
	overflow-x: hidden;
	overflow-y: scroll;
	height: 65vh;
}
.table-analysisMenu .table-scroll th:not(:first-child),
.table-analysisMenu .table-scroll td:not(:first-child) {
	width: 100%;
}



/* �O���t�iChartJs Canvas�j */
.chart canvas {
	max-width: 100%;
	max-height: 100%;
	width: 100% !important;
}

/* �O���t�Q�� */
.chartReportLowBox {
	min-height: 220px;
	max-height: 220px;
}
/* �\�{�����O���t�R�� */
.chartReportLowestBox {
	min-height: 200px;
	max-height: 200px;
}

/* ���ڕʃ����L���O�i���C�_�[�}�j */
/* ��������i���ł��邽�ߒ������K�v�j */
.chartRadarBox {
	min-height: 380px;
}
@media print {
	.chartRadarBox {
		min-width: 247mm;
		min-height: 160mm;
		max-width: 247mm;
		max-height: 160mm;
		width: 100%;
	}
	.chartRadarBox canvas {
		min-height: 100%;
		min-width: 100%;
		max-width: 100%;
		max-height: 100%;
		height: auto!important;
		width: auto!important;
	}
}

