$light: #e6f2fc;

.date-select{
  border-radius: 5px;
  background-color: white;
  border-color: #bbb;
}

.jss_content{
  overflow: auto; box-shadow: rgb(221, 221, 221) 2px 2px 5px 0.1px; max-height: 62vh !important; width: 100%;
}

.jss_dropdown{
  padding-right: 30px !important;
}

.jss_content table thead tr td{
  width: 3000px !important
}

.jss_header{
  text-align: center !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.selected-item {
  background-color: #ddd;
}

.login-button {
  padding: 10px 0;
  font-weight: bold;
}

.custom-dropdown div {
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 10px #d5d5d5
}

.jss {
  font-size: 18px;
}

#data-table .jss tbody [data-x="2"], #data-table  .jss tbody [data-x="3"] {
  font-size: 14px;
}

#formulas-table .jss tbody [data-x="1"] {
  font-size: 15px;
}

.month-orange {
  background-color: #f7ece4 !important;
  color: #000000 !important;
}

.month-green {
  background-color: #eef7ef !important;
  color: #000000 !important;
}


#data-table tbody {
  font-size: 16px;
}


.jss>tbody>tr>td.readonly {
  color: rgba(0,0,0);
}

.custom-dropdown div a:hover {
  background-color: $light;
}

.custom-dropdown div a {
  padding: 10px 20px !important;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

}

.dropdown .dropdown-menu{
  pointer-events: none;
  display: block;
  opacity:0;
  max-height: 0;
  -webkit-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.dropdown-menu.show {
  pointer-events: auto;
  max-height: 500px;
  display: block;
  opacity: 1;
}

.form-wrapper{
  border-radius: 10px;
  box-shadow: 0 0 15px #ddd
}

.auth-header {
  color: #408d11;
  font-family: 'Fredoka One', cursive;
  font-size: 30px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #408d11;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.auth-header span {
  background:#fff;
  padding:0 10px;
}
