/* flatpickrカスタマイズ */
.flatpickr-calendar {
	background: #fff;
}
.flatpickr-rContainer {
	padding: 2px;
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
	fill: #569ff7;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #ffffff;
}

/* flatpickr 月 */
.flatpickr-months .flatpickr-month {
	height: 40px;
	margin: 1% 1% 0 1%;
	background: #eee;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.flatpickr-current-month {
	margin-top: 2%;
	padding: 3.74px;
	width: 100%;
	left: 0;
}
.flatpickr-months .flatpickr-next-month {
	margin-top: 2%;
	margin-right: 2%;
	padding-top: 4px;
	background: #f0f0f0;
	border-radius: 2px;
}
.flatpickr-months .flatpickr-prev-month {
	margin-top: 2%;
	margin-left: 2%;
	padding-top: 3.74px;
	background: #f0f0f0;
	border-radius: 2px;
}
.flatpickr-monthSelect-months {
	margin: 1%;
	margin: 0;
	background: #fff;
}
.flatpickr-monthSelect-month {
	width: 33%;
	background: #fff;
	border: 1px solid #f0f0f0;
	border-radius: 2px;
}

/* flatpickr 日 */
.dayContainer {
	padding: 0;
	outline: 0;
	text-align: left;
	width: 303.875px;
	min-width: 303.875px;
	max-width: 303.875px;
}
.flatpickr-day {
	border-radius: 2px;
	margin-bottom: 2px;
	max-width: 42px;
	border: 1px solid #f0f0f0;
}
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
	border: 1px solid #fafafa;
}
