/* 印刷関連 */
@page {
    size: auto!important;
}
@media print {
    .table-responsive {
        -webkit-overflow-scrolling: touch;
        overflow-x: unset;
    }
    .col-12-print {
        flex: 0 0 auto;
        width: 100%;
    }
    .p-0-print {
        padding: 0 !important;
    }
    .py-0-print {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

/* -- ページネーション関連 --*/
.pagination {
    --bs-base-color: #3b7ddd;
    --bs-base-hover-color: #326abc;

    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.3rem;
    --bs-pagination-font-size: .875rem;
    --bs-pagination-color:                 var(--bs-base-color);
    --bs-pagination-bg:                    #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color:          #dee2e6;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color:           var(--bs-base-hover-color);
    --bs-pagination-hover-bg:              #e9ecef;
    --bs-pagination-hover-border-color:    #dee2e6;
    --bs-pagination-focus-color:           var(--bs-base-hover-color);
    --bs-pagination-focus-bg:              #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color:          #fff;
    --bs-pagination-active-bg:             #3b7ddd;
    --bs-pagination-active-border-color:   #3b7ddd;
    --bs-pagination-disabled-color:        #c8ccd0;
    --bs-pagination-disabled-bg:           #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none;
}


/* -- テキストサイズ関連 -- */
.fs-xss {
    font-size: .625rem;
}
.fs-xs {
    font-size: .750rem;
}
.fs-sm {
    font-size: .875rem;
}
.fs-md {
    font-size: 1.000rem;
}
.fs-lg {
    font-size: 1.125rem;
}
.fs-xl {
    font-size: 1.250rem;
}
.fs-xxl {
    font-size: 1.375rem;
}


/* -- ページ上部へ戻る -- */
.scroll-to-top {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #eee;
    background: rgba(52,58,64,.4);
    border-radius: 0.2rem;
    z-index: 999;
    opacity: 0;
    display: none;
    transition: 0.3s linear;
}
.scroll-to-top:focus, .scroll-to-top:hover {
    color: #fff
}
.scroll-to-top:hover {
    background: #343a40
}
.scroll-to-top i {
    font-weight: 800
}


/* -- input-group-icon（テキスト内アイコン） -- */
.input-group-icon .form-control:focus + .input-group-text-icon,
.input-group-icon .form-select:focus + .input-group-text-icon {
    box-shadow: none !important;
}

.input-group-icon .form-control {
    border-right: 0;
    padding-right: 0;
}
.input-group-icon .input-group-text-icon {
    align-items: center;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-left: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #495057;
    display: flex;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.3rem 0.85rem;
    text-align: center;
    white-space: nowrap;
}
.input-group-icon .form-control:focus + .input-group-text-icon {
    color: #495057;
    background-color: #fff;
    border-color: #9dbeee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(59 125 221 / 25%);
}
.input-group-icon .form-control:disabled + .input-group-text-icon,
.input-group-icon .form-control[readonly] + .input-group-text-icon {
    background-color: #e9ecef;
    opacity: 1;
}
.form-control[readonly],
.input-group-icon .form-control[readonly] + .input-group-text-icon {
    opacity: 0.5;
}
.flatpickr-input[readonly],
.input-group-icon .flatpickr-input[readonly] + .input-group-text-icon {
    background-color: #fff;
    opacity: 1;
}



/* -- login関連 -- */
.login-page {
    -ms-flex-align: center;
    align-items: center;
    background-color: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}
.login-box {
    width: 380px;
}
@media (max-width: 576px) {
    .login-box {
        width: 95%;
    }
}
.version {
    color: #777;
    font-size: 0.9rem;
}


/* -- サイドメニュ関連 -- */
/* サイドバートグル（三本メニューボタン） */
.sidebar-toggle, .sidebar-toggle:hover {
    color: #212529;
    text-decoration: none;
    font-size: 1.3rem;
}


/* -- サイドメニューの矢印 -- */
.sidebar [data-bs-toggle=collapse]:after {
    border: solid;
    border-width: 0 0.075rem 0.075rem 0;
    content: " ";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.2rem;
    transform: rotate(45deg);
    transition: all .2s ease-out;
}


/* ---- パンくず表示 ---- */
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 1rem;
    padding: 0;
    font-size: .875rem;
}
.breadcrumb-item.active {
    color: #6c757d;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}
.breadcrumb-item+.breadcrumb-item:before {
    color: #6c757d;
    content: var(--bs-breadcrumb-divider,"/");
    float: left;
    padding-right: 0.5rem;
}


/* ---- タブ関連 ---- */
.tab {
    margin-bottom: 2rem;
}
.tab .nav-tabs {
    border: 0;
}
.tab .nav-tabs .nav-link {
    background: transparent;
    border: 0;
    color: #343a40;
    padding: 0.75rem 1rem;
    margin-bottom: -1px;
    margin-right: 1px;
}
.tab .nav-tabs .nav-link.active {
    color: #343a40;
}
.tab .tab-content {
    border-radius: 0 0 0.2rem 0.2rem;
    box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 5%);
    padding: 1.25rem;
}
.tab-vertical .tab-content {
    overflow: auto;
}

.tab  .tab-content>.tab-pane {
    display: none;
}
.tab  .tab-content>.active {
    display: block;
}

/* 縦タブ */
.tab-vertical .nav-tabs {
    flex-direction: column;
    float: left;
}
.tab-vertical .nav-tabs .nav-link {
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0;
    position: relative;
    margin-right: -1px;
    margin-bottom: 1px;
}

/* tab タブの枠線 */
.tab-border .nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    background: #fff;
}
.tab-border .nav-tabs .nav-link.active {
    border: 1px solid #dee2e6;
    border-bottom: 1px solid #fff;
}
.tab-border .tab-content {
    border: 1px solid #dee2e6;
    border-color: #dee2e6;
    background: #fff;
}

/* tab 縦タブの枠線 */
.tab-vertical.tab-border .nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.tab-vertical.tab-border .nav-tabs .nav-link.active {
    border: 1px solid #dee2e6;
    border-right: 1px solid #fff;
}

/* tab 非アクティブタブの背景色 */
.tab-noactive-lightgray .nav-tabs .nav-link:not(.active) {
    background: #f7f7f7;
    color: #343a40;
}

/* tab タイトル */
.tab-title {
    font-size: .925rem;
}




/* -- callout -- */
.callout.callout-primary {
    border-left-color: #3b7ddd;
}
.callout.callout-secondary {
    border-left-color: #6c757d;
}
.callout.callout-success {
    border-left-color: #1cbb8c;
}
.callout.callout-info {
    border-left-color: #17a2b8;
}
.callout.callout-warning {
    border-left-color: #fcb92c;
}
.callout.callout-danger {
    border-left-color: #dc3545;
}
.callout.callout-light {
    border-left-color: #f5f7fb;
}
.callout.callout-dark {
    border-left-color: #212529;
}
.callout.callout-white {
    border-left-color: #fff;
}
.callout {
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background-color: #fff;
    border-left: 5px solid #e9ecef;
    margin-bottom: 1rem;
    padding: 1rem;
}

/* table関連 */
/* 折り返し禁止 */
.table-nowrap {
    white-space: nowrap;
}
.table-thead-nowrap thead {
    white-space: nowrap;
}
.table-tbody-nowrap tbody {
    white-space: nowrap;
}
.table-th-nowrap th {
    white-space: nowrap;
}
.table-td-nowrap ts {
    white-space: nowrap;
}


/* ページネーション */
.page-link {
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #6c757d;
}
.page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #343a40;
}
.page-item.active .page-link {
    background-color: #3b7ddd;
    border-color: #3b7ddd;
}
.pagination-sm .page-item:first-child .page-link {
    border-bottom-left-radius: 0.1rem;
    border-top-left-radius: 0.1rem;
}
.pagination-sm .page-link {
    font-size: .75rem;
    padding: 0.15rem 0.5rem;
}
.pagination-sm .page-item:last-child .page-link {
    border-bottom-right-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
}
.pagination-lg .page-item:first-child .page-link {
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}
.pagination-lg .page-link {
    font-size: .925rem;
    padding: 0.35rem 1rem;
}
.pagination-lg .page-item:last-child .page-link {
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

/* マスタ管理一覧 〇件 */
.page-item-count {
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    color: #495057;
    display: block;
    font-size: .75rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 0.3rem 0.3rem;
    width: 100%;
}
